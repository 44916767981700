import React from 'react';
import {Container } from 'react-bootstrap';

function DebatesPage() {
   
    return (
        <Container fluid className='px-5 py-5 context-container'>
            Debates
        </Container>
    );
}
export default DebatesPage;