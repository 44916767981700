/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://bo5o3yg4yne6xpljwqnpg4agsq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gk5rbkqa45cbhaffyyuiactoni",
    "aws_cognito_identity_pool_id": "us-east-1:e36e96cc-9b5a-4552-84b6-889a31011d40",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HRu39PslW",
    "aws_user_pools_web_client_id": "i3urrgfus5a3vpr4sdmjit8du",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "argumentsandfacts9d6deadd55494253a87fb239a6e6e8221710-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
